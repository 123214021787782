import React from 'react'
import LandingPage from './pages/LandingPage'
// import MyRoute from './routes'

const App = () => {
  return (
    <div>
      <LandingPage/>
     
    </div>
  )
}

export default App
